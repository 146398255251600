import {
  deployNewPool,
  ExchangeLPoolHistoryDto,
  fetchAllPools,
  getPoolBalances,
  getPoolTxHistory,
  withdrawFromPool,
} from "../../api";
import { useEffect } from "react";
import {useAuth, useErrorHandler} from "../../hooks";
import { LiquidityPoolBalance } from "../../typings";
import { formatError } from "../../api";
import { ContextActionReturnType } from "../../typings";
import { createContext, useCallback, useMemo, useState } from "react";
import {
  Account,
  ExchangeSvcLiquidityPoolDto,
  PoolWithdrawalTransactionDto,
} from "@nestcoinco/onboard-api-gateway-api-client";
import { createAccount, getCustomerAccounts } from "../../api/accounts";
import { useQuery } from "react-query";

export interface WithdrawaAseetRequest {
  poolAddress: string;
  networkId: string;
  asset: string;
  amount: any;
}

interface AccountContextProps {
  loading: boolean;
  isFetching: boolean;
  totalUsdBalance: number;
  loadingBalances: boolean;
  fetchingAllTx: boolean;
  allTransactions: ExchangeLPoolHistoryDto[];
  accounts: ExchangeSvcLiquidityPoolDto[] | undefined;
  recentTransactions: ExchangeLPoolHistoryDto[];
  fetchAllTransactions: VoidFunction;
  withdrawAsset: (
    input: WithdrawaAseetRequest
  ) => Promise<
    ContextActionReturnType<PoolWithdrawalTransactionDto> | undefined
  >;
  fetchAll: (networkId?: string) => Promise<void>;
  fetchPoolBalances: (poolAddress: string, networkId?: string) => Promise<void>;
  returnPoolBalances: (
    poolAddress: string,
    networkId?: string
  ) => LiquidityPoolBalance[];
  create: (
    networkId: string,
    address: string
  ) => Promise<ContextActionReturnType | void>;
  getPoolTransactions: (
    poolAddress: string,
    networkId?: string
  ) => ExchangeLPoolHistoryDto[];
  customerAccounts: Account[] | undefined;
  loadingCustomerAccounts: boolean;
}

const lc = (key: string): string => String(key).toLowerCase();

export const AccountContext = createContext<AccountContextProps | null>(null);

const AccountProvider = (prop: any) => {
  const { toastError } = useErrorHandler();
  const { authToken, verified, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [fetchingAllTx, setFetchingAllTx] = useState<boolean>(false);
  const [loadingBalances, setLoadingBalances] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<
    ExchangeSvcLiquidityPoolDto[] | undefined
  >();
  const [allTransactions, setAllTransactions] = useState<
    ExchangeLPoolHistoryDto[]
  >([]);
  const [poolBalanceMap, setPoolBalanceMap] = useState<
    Record<string, LiquidityPoolBalance[]>
  >({});
  const {
    data,
    isLoading: loadingCustomerAccounts,
    refetch: refetchCustomersAccount,
  } = useQuery(
    `customer-accounts-${user?.userId}`,
    () => getCustomerAccounts(authToken!),
    {
      onSuccess(data) {
        if (data?.content!.length) {
          return data.content;
        }
        createCustomerAccount();
      },
      enabled: !!authToken && verified,
    }
  );

  const create = useCallback(
    async (networkId: string, address: string) => {
      setLoading(true);
      try {
        await deployNewPool(networkId, address, authToken!);
      } catch (e) {
        return { error: formatError(e) };
      } finally {
        setLoading(false);
      }
    },
    [authToken]
  );

  const fetchAll = useCallback(
    async (networkId?: string) => {
      if (!authToken || !verified) return;
      setLoading(true);
      fetchAllPools(authToken!, networkId)
        .then(setAccounts)
        .catch(toastError)
        .finally(() => setLoading(false));
    },
    [authToken, toastError, verified]
  );

  const fetchPoolBalances = useCallback(
    async (poolAddress: string, networkId?: string) => {
      if (!authToken || !verified) return;
      getPoolBalances(authToken!, poolAddress, networkId)
        .then((balances) => balances.map((b) => ({ ...b, poolAddress })))
        .then((balances: LiquidityPoolBalance[]) => {
          if (!networkId) {
            setPoolBalanceMap((p) => ({ ...p, [lc(poolAddress)]: balances }));
            return;
          } else {
            const updatePool = (poolBalanceMap[lc(poolAddress)] ?? [])
              .filter((p) => p.networkId !== networkId)
              .concat(balances);
            setPoolBalanceMap((p) => ({ ...p, [lc(poolAddress)]: updatePool }));
          }
        });
    },
    [authToken, poolBalanceMap, verified]
  );

  const withdrawAsset = useCallback(
    async (input: WithdrawaAseetRequest) => {
      try {
        if (!authToken || !verified) return;
        const data = await withdrawFromPool(
          authToken!,
          input.poolAddress,
          input.networkId,
          input.asset,
          input.amount
        );
        return {
          data,
        };
      } catch (error: any) {
        return {
          error: formatError(error),
        };
      }
    },
    [authToken, verified]
  );

  const fetchAllTransactions = useCallback(() => {
    if (!authToken || !verified) return;
    setFetchingAllTx(true);
    getPoolTxHistory(authToken!)
      .then(setAllTransactions)
      .catch(toastError)
      .finally(() => setFetchingAllTx(false));
  }, [authToken, toastError, verified]);

  const getPoolTransactions = useMemo(
    () => (poolAddress: string, networkId?: string) => {
      return allTransactions.filter(
        (tx) =>
          lc(tx.poolAddress) === lc(poolAddress) &&
          (networkId ? networkId === tx.networkId : true)
      );
    },
    [allTransactions]
  );

  const totalUsdBalance = useMemo(
    () =>
      Object.values(poolBalanceMap)
        .flat(1)
        .map((p) => p.balanceInUsd!)
        .reduce((a, b) => a + b, 0),
    [poolBalanceMap]
  );

  const returnPoolBalances = useMemo(
    () => (poolAddress: string, networkId?: string) => {
      if (networkId) {
        return (poolBalanceMap[lc(poolAddress)] ?? []).filter(
          (b) => b.networkId === networkId
        );
      } else {
        return poolBalanceMap[lc(poolAddress)] ?? [];
      }
    },
    [poolBalanceMap]
  );

  const recentTransactions = useMemo(
    () => allTransactions.slice(0, 5),
    [allTransactions]
  );

  useEffect(() => {
    const loadBalances = async () => {
      for (const account of accounts ?? []) {
        if (account.address) {
          await fetchPoolBalances(account.address!);
        }
      }
    };
    if ((accounts ?? []).length) {
      setLoadingBalances(true);
      loadBalances()
        .catch(toastError)
        .finally(() => setLoadingBalances(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, toastError]);

  useEffect(() => {
    if (!authToken || !verified) return;
    setIsFetching(true);
    fetchAllPools(authToken!)
      .then(setAccounts)
      .catch(toastError)
      .finally(() => setIsFetching(false));
    fetchAllTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, toastError, verified]);

  const createCustomerAccount = useCallback(() => {
    createAccount(authToken!)
      .then(() => refetchCustomersAccount())
      .catch(toastError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  const values = useMemo<AccountContextProps>(
    () => ({
      loading,
      create,
      isFetching,
      accounts,
      fetchAll,
      fetchingAllTx,
      totalUsdBalance,
      loadingBalances,
      fetchPoolBalances,
      returnPoolBalances,
      recentTransactions,
      fetchAllTransactions,
      getPoolTransactions,
      allTransactions,
      withdrawAsset,
      customerAccounts: data?.content,
      loadingCustomerAccounts,
    }),
    [
      loading,
      create,
      isFetching,
      accounts,
      fetchAll,
      fetchingAllTx,
      totalUsdBalance,
      loadingBalances,
      fetchPoolBalances,
      returnPoolBalances,
      recentTransactions,
      fetchAllTransactions,
      getPoolTransactions,
      allTransactions,
      withdrawAsset,
      data,
      loadingCustomerAccounts,
    ]
  );

  return (
    <AccountContext.Provider value={values}>
      {prop.children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
