import { FC, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  OnboardConnectItems,
  OpnItems,
  sidebarItems,
} from "../../constants/sidebar";
import { OnboardLogo } from "../Icons";
import { CloseOutline } from "react-ionicons";
import { useWindowWidth } from "@react-hook/window-size";
import { screenLg } from "../../constants/screen";
import SidebarItem from "../SidebarItem";
import useProductAccess from "../../hooks/useProductAccess/useProductAccess";

interface SidebarProps {
  collapsed: boolean;
  toggle: () => void;
}

const Sidebar: FC<SidebarProps> = ({ collapsed, toggle }) => {
  const location = useLocation();
  const width = useWindowWidth();
  const { hasDefiOnboardProduct, hasNonDefiOnboardProduct, hasOpnProduct } =
    useProductAccess();

  const mapNavItem = useCallback(
    (item: { to: string; text: string; icon: JSX.Element }) => ({
      ...item,
      onClick: () => (width < screenLg ? toggle() : null),
      active:
        location.pathname === item.to ||
        `/${location.pathname.split("/")[1]}` === item.to,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, width]
  );

  const generalSidebarItems = useMemo(() => {
    const topItems = sidebarItems.top.map(mapNavItem);
    const bottomItems = sidebarItems.bottom.map(mapNavItem);

    return {
      topItems,
      bottomItems,
    };
  }, [mapNavItem]);

  const onboardConnectItems = useMemo(() => {
    const generalLinks = OnboardConnectItems.general.map(mapNavItem);
    const setupLinks = OnboardConnectItems.serviceSetup.map(mapNavItem);
    return {
      general: [...generalLinks],
      serviceSetup: [...setupLinks],
    };
  }, [mapNavItem]);

  const opnItems = useMemo(() => OpnItems.map(mapNavItem), [mapNavItem]);

  return (
    <section
      className={` h-full ${
        collapsed ? "w-[0px]" : "w-[260px]"
      } fixed transition-all duration-500`}
      style={{ zIndex: 999 }}
    >
      <div className="w-full z-50 h-full bg-white shadow flex-col justify-between flex overflow-auto">
        <div className="relative">
          <span
            className={`absolute top-3 right-2 cursor-pointer ${
              width > screenLg ? "hidden" : ""
            }`}
            onClick={toggle}
          >
            <CloseOutline />
          </span>
          <div className="h-[40px] w-[170px] mt-4 px-8 flex items-center">
            <OnboardLogo />
          </div>
          <ul className="mt-8 ">
            {generalSidebarItems.topItems.map((item, i) => (
              <SidebarItem {...item} key={i} />
            ))}
            {hasDefiOnboardProduct ? (
              <section className="mt-4">
                <h2 className="font-bold pl-8">Onboard connect</h2>
                <ul className="ml-4">
                  <div className="">
                    {onboardConnectItems.general.map((item, i) => (
                      <SidebarItem {...item} key={i} />
                    ))}
                  </div>
                  {hasNonDefiOnboardProduct && (
                    <>
                      <h3 className="pl-8 font-medium mt-2">
                        Centralized service setup
                      </h3>
                      <div>
                        {onboardConnectItems.serviceSetup.map((item, i) => (
                          <SidebarItem {...item} key={i} />
                        ))}
                      </div>
                    </>
                  )}
                </ul>
              </section>
            ) : null}
            {hasOpnProduct ? (
              <section className="mt-4 mb-2">
                <h2 className="font-bold pl-8">OPN</h2>
                <ul className="ml-4">
                  {opnItems.map((item, i) => (
                    <SidebarItem {...item} key={i} />
                  ))}
                </ul>
              </section>
            ) : null}
            {generalSidebarItems.bottomItems.map((item) => (
              <SidebarItem {...item} key={item.to} />
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
