import { useEffect, useState } from "react";
import { ArrowBackOutline } from "react-ionicons";
import { useNavigate } from "react-router-dom";
import { getPaymentChannels } from "../../../api/payouts";
import { Button, Card, DropDown } from "../../../components";
import { DropdownItem } from "../../../components/Dropdown";

import PageWrapper from "../../../components/PageWrapper";
import { useAuth } from "../../../hooks";
import useWithdrawal from "../../../hooks/useWithdrawal";

const OnboardFast = () => {
  const navigate = useNavigate();
  const { authToken, verified } = useAuth();
  const { getReadablePaymentChannels, selectedMethod, setSelectedMethod } =
    useWithdrawal();
  const [selectedCurrency, setSelectedCurrency] = useState<DropdownItem>();
  const [paymentChannels, setPaymentChannels] = useState<DropdownItem[]>([]);

  useEffect(() => {
    setSelectedMethod(undefined);
    if (!authToken || !verified) return;
    getPaymentChannels(authToken).then((channels) => {
      const formattedChannels = channels?.map((channel: any) => {
        return {
          value: channel.type as string,
          text: getReadablePaymentChannels(channel.type),
          icon: "",
        } as DropdownItem;
      });
      setPaymentChannels(formattedChannels!);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate("/withdrawal");
  };

  return (
    <PageWrapper title="Onboard Fast" hideCrumb>
      <p className="mt-4 flex cursor-pointer" onClick={handleBack}>
        <ArrowBackOutline /> Back{" "}
      </p>

      <div className="flex justify-center w-full">
        <Card
          classNames="py-12 px-6 mt-20 min-w-[400px] max-w-[500px] justify-center bg-white shadow-md rounded-md"
          children={
            <>
              <p className="text-center">
                Select local currency for withdrawal
              </p>

              <div className="w-full border rounded my-6 bg-gray-200">
                <DropDown
                  items={[
                    {
                      value: "NGN",
                      text: "NGN",
                      icon: "",
                    },
                  ]}
                  value={selectedCurrency}
                  setValue={(item) => setSelectedCurrency(item)}
                  variant="text"
                  placeholder="Select currency"
                />
              </div>
              <div className="w-full border rounded my-6 bg-gray-200">
                <DropDown
                  items={paymentChannels}
                  value={selectedMethod}
                  setValue={(item) => setSelectedMethod(item)}
                  variant="text"
                  placeholder="Payment method"
                />
              </div>

              <div className="w-full flex justify-center">
                <Button
                  text={"Continue"}
                  disabled={!selectedMethod || !selectedCurrency}
                  onClick={() => {
                    if (!selectedCurrency || !selectedMethod) {
                      return;
                    }
                    navigate("convert", {
                      state: {
                        paymentMethod: selectedMethod.value,
                        localCurrency: selectedCurrency.value,
                      },
                    });
                  }}
                />
              </div>
            </>
          }
        />
      </div>
    </PageWrapper>
  );
};

export default OnboardFast;
