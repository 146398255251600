// config.ts
import axios, { AxiosInstance } from "axios";
import {
  ExchangeAuthClient,
  ExchangeCexsClient,
  ExchangeUsersClient,
  Configuration,
  AssetManagerClientClient,
  ExternalAuthApikeysClient,
  ExchangeOrdersClient,
  LedgerAccountsClient,
  LedgerTransferClient,
  LedgerPaymentsClient,
  PayoutsClientsClient,
  PayoutsProvidersClient,
  BusinessTransactionsTransactionsClient,
  BusinessTransactionsAccountsClient,
  OrdersOrdersClient,
  OrdersQuotesClient,
} from '@nestcoinco/onboard-api-gateway-api-client';

import { BASE_PATH } from '@nestcoinco/onboard-api-gateway-api-client/dist/base';
import { authForbidden } from '../constants/events';
const basePath = process.env.REACT_APP_API_BASE_PATH || BASE_PATH;

export const config = new Configuration({ basePath });

export const axiosInstance = axios.create({});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      window.dispatchEvent(new CustomEvent(authForbidden, { bubbles: true }));
      throw null;
    }
    throw error;
  }
);

export type TApiClient<T> = {
  new (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
  ): T;
};

function createClientInstance<U>(Target: TApiClient<U>) {
  return new Target(config, undefined, axiosInstance);
}

const exchangeAuthClientInstance = createClientInstance(ExchangeAuthClient);
const exchangeUserClientInstance = createClientInstance(ExchangeUsersClient);
const exchangeCexClientInstance = createClientInstance(ExchangeCexsClient);
const assetClientInstance = createClientInstance(AssetManagerClientClient);
const ledgerAccountsClientInstance = createClientInstance(LedgerAccountsClient);
const businessTransactionsClientInstnce = createClientInstance(
  BusinessTransactionsTransactionsClient
);
const businessTransactionsAccountClientInstance = createClientInstance(
  BusinessTransactionsAccountsClient
);
const exchangeApiKeyClientInstance = createClientInstance(
  ExternalAuthApikeysClient
);
const exchangeOrderClientInstance = createClientInstance(ExchangeOrdersClient);
const ledgerAccountClientInstance = createClientInstance(LedgerAccountsClient);
const ledgerTransferClientInstance = createClientInstance(LedgerTransferClient);
const ledgerPaymentsClientInstance = createClientInstance(LedgerPaymentsClient);
const payoutsClientsClientInstance = createClientInstance(PayoutsClientsClient);
const payoutsProvidersClientInstance = createClientInstance(PayoutsProvidersClient);
const ordersQuotesClientInstance = createClientInstance(OrdersQuotesClient);
const ordersOrdersClientInstance = createClientInstance(OrdersOrdersClient);

const ApiClient = {
  exchange: {
    auth: exchangeAuthClientInstance,
    user: exchangeUserClientInstance,
    cex: exchangeCexClientInstance,
    apiKey: exchangeApiKeyClientInstance,
    order: exchangeOrderClientInstance,
  },
  asset: assetClientInstance,
  ledger: {
    account: ledgerAccountClientInstance,
    transfer: ledgerTransferClientInstance,
    payments: ledgerPaymentsClientInstance
  },
  order: {
    quotes: ordersQuotesClientInstance,
    orders: ordersOrdersClientInstance
  },
  payouts: {
    clients: payoutsClientsClientInstance,
    providers: payoutsProvidersClientInstance
  },
  accounts: ledgerAccountsClientInstance,
  businessTransactions: businessTransactionsClientInstnce,
  businessAccountsTransactions: businessTransactionsAccountClientInstance,
  ordersClient: ordersOrdersClientInstance
};

export default ApiClient;

export const getTokenHeader = (token: string) => ({
  headers: {
    "x-auth-token": token,
  },
});
