import { ApiKeyOnly } from '@nestcoinco/onboard-api-gateway-api-client';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  destroyApiKeys,
  generateOrRegenerateApiKeys,
  getApiKeySecret,
  getApiPubKeys,
  formatError,
} from '../../api';
import {useAuth, useErrorHandler} from '../../hooks';
import { ContextActionReturnType } from '../../typings';

export type IFullKey = ApiKeyOnly & {
  secret?: string;
};

interface ApiKeyContextProps {
  loading: boolean;
  isFetching: boolean;
  apiKeys?: ApiKeyOnly[];
  loadAllKeys: () => Promise<ContextActionReturnType | void>;
  loadFullKeys: (totp: string, credentialId: string) => Promise<ContextActionReturnType | void>;
  generateKeys: (totp: string, credentialId?: string) => Promise<ContextActionReturnType | void>;
  destroyKeys: (totp: string, credentialId: string) => Promise<ContextActionReturnType | void>;
  hideKeySecret: (credentialId: string) => ContextActionReturnType | void;
}

export const ApiKeyContext = React.createContext<ApiKeyContextProps | null>(null);

const ApiKeyProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [apiKeys, setApiKeys] = useState<IFullKey[] | undefined>();

  const { addToast } = useToasts();
  const { authToken, verified } = useAuth();
  const { toastError } = useErrorHandler();

  const replaceOrAddKey = useCallback(
    (key: Partial<IFullKey>) => {
      let keyIndex = apiKeys?.findIndex((k) => k.id === key?.id);
      if((keyIndex ?? -1) < 0) {
        keyIndex = apiKeys?.length ?? 0;
      }
      console.log({keyIndex})
      const updatedKeys = apiKeys ?? [];
      updatedKeys[keyIndex!] = {...updatedKeys[keyIndex!], ...key } as IFullKey;
      setApiKeys(updatedKeys);
    },
    [apiKeys]
  );

  const hideKeySecret = useCallback(
    (credentialId: string) => {
      const keys = apiKeys?.find(k => k.id === credentialId);
      if(keys) {
        delete keys['secret'];
        replaceOrAddKey(keys);
        addToast('Key secret hidden', { appearance: 'success' });
        return
      }
      addToast('Key not found', { appearance: 'error' });
    },
    [addToast, apiKeys, replaceOrAddKey],
  )


  const loadAllKeys = useCallback(async () => {
    if (!authToken || !verified) return;
    const keys = await getApiPubKeys(authToken);
    setApiKeys(keys);
  }, [authToken, verified]);

  const loadFullKeys = useCallback(
    async (totp: string, credentialId: string) => {
      try {
        if (!authToken) {
          return { error: formatError('Authentication session not found!') };
        }
        setLoading(true);
        const keys = await getApiKeySecret(authToken, credentialId, totp)!;
        replaceOrAddKey({ id: credentialId, secret: keys?.secret });
      } catch (e) {
        return { error: formatError(e) };
      } finally {
        setLoading(false);
      }
    },
    [authToken, replaceOrAddKey]
  );

  const generateKeys = useCallback(
    async (totp: string, credentialId?: string) => {
      try {
        if (!authToken) {
          return { error: formatError('Authentication session not found!') };
        }
        setLoading(true);
        const pubKey = await generateOrRegenerateApiKeys(authToken, { totp, credentialId });
        replaceOrAddKey(pubKey!);
        addToast('API keys generated', { appearance: 'success' });
      } catch (e) {
        return { error: formatError(e) };
      } finally {
        setLoading(false);
      }
    },
    [addToast, authToken, replaceOrAddKey]
  );

  const destroyKeys = useCallback(
    async (totp: string, credentialId: string) => {
      try {
        if (!authToken) {
          return { error: formatError('Authentication session not found!') };
        }
        setLoading(true);
        await destroyApiKeys(authToken, credentialId, totp);
        setApiKeys((keys) => keys?.filter((k) => k.id !== credentialId));
        addToast('API keys removed', { appearance: 'success' });
      } catch (e) {
        return { error: formatError(e) };
      } finally {
        setLoading(false);
      }
    },
    [addToast, authToken]
  );

  useEffect(() => {
    setIsFetching(true);
    loadAllKeys()
      .catch(toastError)
      .finally(() => setIsFetching(false));
  }, [toastError, loadAllKeys]);

  const value = useMemo(
    () => ({
      loading,
      isFetching,
      apiKeys,
      loadAllKeys,
      loadFullKeys,
      generateKeys,
      destroyKeys,
      hideKeySecret,
    }),
    [apiKeys, destroyKeys, generateKeys, hideKeySecret, isFetching, loadAllKeys, loadFullKeys, loading]
  );

  return <ApiKeyContext.Provider value={value}>{children}</ApiKeyContext.Provider>;
};

export default ApiKeyProvider;
