import React from "react";
import { useSearchParams } from "react-router-dom";

interface ITabNavListItm {
  id: string;
  title: string;
  controls: string;
  tabParam?: string;
  showAsDefault?: boolean;
  className?: string;
}

const TabNavListItm: React.FC<ITabNavListItm> = ({
  id,
  title,
  controls,
  tabParam,
  showAsDefault,
  className,
}) => {
  const [, setSearchParam] = useSearchParams();

  const handleSetTabParam = () => {
    if (tabParam) {
      setSearchParam({ tab: tabParam });
    }
  };
  return (
    <li className="nav-item" role="presentation">
      <a
        id={id}
        role="tab"
        href={`#${controls}`}
        className={`
        nav-link block text-gray-600 font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent 
        ${showAsDefault ? "active" : ""} ${className}
      `}
        data-bs-toggle="pill"
        data-bs-target={`#${controls}`}
        aria-controls={controls}
        aria-selected={showAsDefault ? "true" : "false"}
        onClick={() => handleSetTabParam()}
      >
        {title}
      </a>
    </li>
  );
};

export default TabNavListItm;
