import React, { useState } from "react";
import { useDepositContext } from "..";
import { Button, Copier } from "../../../components";
import { QRCodeSVG } from "qrcode.react";
import {useAccounts, useAuth, useErrorHandler} from "../../../hooks";
import { createFundingAddress } from "../../../api/accounts";
import BackButton from "../../../components/Button/BackButton";

const DepositAddress = () => {
  const { authToken, verified } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { state, dispatch } = useDepositContext();
  const account = useAccounts().customerAccounts?.[0];
  const { toastError } = useErrorHandler();


  function createAddress() {
    if (!authToken || !verified) return;
    setIsLoading(true);
    createFundingAddress(authToken!, account?.id!, state.selectedNetwork?.code!)
      .then((data) => dispatch({ depositAddress: data?.address }))
      .catch(toastError)
      .finally(() => {
        setIsLoading(false);
      });
  }
  return (
    <div className="mx-auto w-[500px]">
      <div className="mt-14">
        <BackButton handleClick={() => dispatch({ step: "network" })} />
      </div>
      <div className="mt-6 bg-white p-10 rounded-[10px]">
        <h1 className="text-center font-semibold pb-5">
          Deposit {state.selectedToken?.name} via{" "}
          {state.selectedNetwork?.code.replace("_", "")} (
          {state.selectedNetwork?.networkInfo.name})
        </h1>

        {state.depositAddress ? (
          <div className="flex flex-col items-center gap-3">
            <p className="text-red-300 text-lg text-center mb-4">
              Only send tokens ({state.selectedToken?.name}) on{" "}
              {state.selectedNetwork?.code.replace("_", "")} network to the
              address to avoid loss of funds
            </p>
            <QRCodeSVG value={state.depositAddress} size={200} />
            <div className="flex flex-col items-center text-center mt-3">
              <h2 className="font-semibold">Address</h2>
              <p className="mb-2"> {state.depositAddress}</p>
              <Copier className="w-full" text={state.depositAddress}>
                Copy wallet address
              </Copier>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <QRCodeSVG value={"Generate address"} size={200} fgColor="grey" />
            <div className="flex flex-col items-center gap-2">
              <p className="mt-6">
                You are yet to generate an address for this network
              </p>
              <Button onClick={createAddress} loading={isLoading}>
                Create address
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DepositAddress;
