/* eslint-disable no-template-curly-in-string */
import * as Yup from "yup";

export const feeConfigSchema = Yup.object().shape({
  tradeType: Yup.string().required("Please select a trade type"),
  tradePercentage: Yup.number()
    .required("Please enter a trade percentage")
    .min(0, "${min} is your minimum trade percentage")
    .max(15, "${max} is your maximum trade percentage"),
});
