import { FC, PropsWithChildren, useMemo } from "react";
import { useLocation } from "react-router-dom";

type PageWrapperProps = PropsWithChildren<{
  title: string;
  hideCrumb?: boolean;
}>;

const PageWrapper: FC<PageWrapperProps> = ({ title, hideCrumb, children }) => {
  const location = useLocation();

  const breadcrumb = useMemo(() => {
    const pathArray = location.pathname.split("/");
    return pathArray.map((path) => path.split("-").join(" ")).join(" - ");
  }, [location]);

  return (
    <main className="py-4 px-7 max-w-screen-2xl mx-auto">
      <div className="flex flex-wrap items-center justify-between gap-4">
        <div>
          <h1 className="text-2xl font-semibold capitalize leading-normal text-gray-800">
            {title}
          </h1>
          {!hideCrumb && (
            <p className="mt-1 text-sm leading-none capitalize text-gray-600">
              Home {breadcrumb}
            </p>
          )}
        </div>
        <div className="flex items-center space-x-3" />
      </div>
      {children}
    </main>
  );
};

export default PageWrapper;
