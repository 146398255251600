import React, { Fragment, useEffect, useState } from "react";
import { AddOutline, EyeOutline, RefreshOutline } from "react-ionicons";
import {
  Button,
  Card,
  Copier,
  Loader,
  TwoFaModal,
} from "../../../../components";
import { useAuth, useConfig } from "../../../../hooks";
import cn from "classnames";

type ActionType = "SHOW_SECRET" | "REGENERATE" | "ADD_NEW";

const WebhookSecret: React.FC<unknown> = () => {
  const {
    loadCexConfigs,
    loadingConfigs,
    cexConfig,
    loading,
    webhookSecret,
    callViewWebhookSecret,
    callGenerateWebhookSecret,
  } = useConfig();
  const { authToken } = useAuth();
  const [show2faModal, setShow2faModal] = useState<boolean>(false);
  const [currentAction, setCurrentAction] = useState<ActionType | undefined>();

  const handleSubmitAction = (totp: string) => {
    if (currentAction === "ADD_NEW") {
      callGenerateWebhookSecret(totp).then(() => setShow2faModal(false));
    }
    if (currentAction === "REGENERATE") {
      callGenerateWebhookSecret(totp, true).then(() => setShow2faModal(false));
    }
    if (currentAction === "SHOW_SECRET") {
      callViewWebhookSecret(totp).then(() => setShow2faModal(false));
    }
  };

  const initAction = (action: ActionType) => {
    setCurrentAction(action);
    setShow2faModal(true);
  };

  useEffect(() => {
    if (!authToken) return;
    loadCexConfigs();
  }, [authToken, loadCexConfigs]);

  return (
    <Fragment>
      {!loadingConfigs ? (
        <Card classNames="mt-7">
          {!cexConfig?.isWebhookSecretSet ? (
            <div className="w-full h-[400px] flex justify-center items-center">
              <Button
                text="Add New Key"
                variant="text"
                icon={<AddOutline color="inherit" />}
                onClick={() => initAction("ADD_NEW")}
              />
            </div>
          ) : (
            <div>
              <div className="flex-grow-1 border p-2 rounded-md mb-4">
                <span className="text-[0.8em] text-gray-600 border px-2 py-1 rounded-md">
                  Webhook Secret
                </span>
                <p className="mt-2 flex gap-5 items-center justify-start">
                  <span
                    className={cn([
                      "text-12 font-bold truncate inline-block max-w-[80%]",
                      {
                        "text-gray-400": !webhookSecret?.secret,
                      },
                    ])}
                  >
                    {webhookSecret?.secret ?? Array(20).fill("x")}
                  </span>
                  {webhookSecret?.secret && (
                    <Copier text={webhookSecret?.secret} />
                  )}
                  <RefreshOutline
                    color="blue"
                    cssClasses="cursor-pointer"
                    onClick={() => initAction("REGENERATE")}
                  />
                  {!webhookSecret?.secret && (
                    <EyeOutline
                      color="blue"
                      cssClasses="cursor-pointer"
                      onClick={() => initAction("SHOW_SECRET")}
                    />
                  )}
                </p>
              </div>
            </div>
          )}
        </Card>
      ) : (
        <div className="w-full h-[200px] flex justify-center items-center">
          <Loader />
        </div>
      )}
      <TwoFaModal
        show={show2faModal}
        setShow={setShow2faModal}
        loading={loading}
        handleSubmit={handleSubmitAction}
      />
    </Fragment>
  );
};

export default WebhookSecret;
