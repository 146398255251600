import { Typography } from "@material-tailwind/react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { AuthCard, Button, Input } from "../../../components";
import { useAuth } from "../../../hooks";
import { loginValidationSchema } from "../../../schemas/auth";

const Login = () => {
  const { authenticate, loading} = useAuth();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      const response = await authenticate(values.email, values.password);

      if (response?.error) {
        return addToast(response.error.message, {
          appearance: "error",
        });
      }
      navigate("/auth/2fa");
    },
  });

  return (
    <AuthCard
      title="Welcome Back!"
      subtitle="Log in to your account to access the Portal"
    >
      <form onSubmit={formik.handleSubmit}>
        <Input
          label="Enter email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          required
          type="email"
          error={formik.errors.email}
        />
        <Input
          label="Enter password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          required
          autoComplete="true"
          type="password"
          error={formik.errors.password}
        />

        <Button
          text="Login"
          type="submit"
          className="mt-5 mb-1"
          fullWidth
          loading={loading}
        />
        <Typography>
          Forgot Password?{" "}
          <Link className="text-blue-500" to="/auth/forgot-password">
            Reset
          </Link>
        </Typography>
      </form>
    </AuthCard>
  );
};

export default Login;
