import { useEffect, useState } from "react";
import { OrdersSvcOrderResponse } from "@nestcoinco/onboard-api-gateway-api-client";
import PageWrapper from "../../components/PageWrapper";
import { Button, Card, Loader } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { enumToReadable } from "../../lib/enumFormatter";
import dayjs from "dayjs";
import { toCommaValue } from "../../lib";
import { useOrder } from "../../hooks";
import { useToasts } from "react-toast-notifications";

const ViewExchange = () => {
  const navigate = useNavigate();
  const { orderRef } = useParams();
  const { fetchOPNOrder } = useOrder();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<OrdersSvcOrderResponse>();

  const handleGoBack = () => {
    navigate("/exchanges");
  };

  useEffect(() => {
    setLoading(true);
    fetchOPNOrder(orderRef!)
      .then((data) => {
        if (data?.error) {
          addToast(data.error, { appearance: "error" });
        }
        if (!data?.data) {
          handleGoBack();
        }
        setOrder(data?.data!);
      })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderRef]);

  return (
    <PageWrapper title="Exchange">
      <div className="flex justify-center w-full">
        <Card
          classNames="py-12 px-6 mt-20 min-w-[400px] max-w-[500px] justify-center bg-white shadow-md rounded-md"
          headingClassNames="mb-8 text-center"
          title="Exchange details"
          children={
            loading || !order ? (
              <Loader color="blue-600" />
            ) : (
              <>
                <div className="w-full">
                  <ul className="my-3">
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Date
                      </span>
                      {dayjs().to(dayjs(order.createdAt))}
                    </li>
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Amount
                      </span>
                      {order.assetCurrency} {toCommaValue(order.amount)}
                    </li>
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Description
                      </span>
                      {enumToReadable(order.status)}{" "}
                      {enumToReadable(order.type)} order
                    </li>
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Fee
                      </span>
                      N/A
                    </li>
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Order Type
                      </span>
                      {enumToReadable(order.type)}
                    </li>
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Rate
                      </span>
                      {order.payoutCurrency} {toCommaValue(order.rate)}/{" "}
                      {order.assetCurrency}
                    </li>
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Total Amount
                      </span>
                      {toCommaValue(order.rate * order.amount)}
                    </li>
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Channel
                      </span>
                      {enumToReadable(order.paymentChannel!)}
                    </li>
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Recipient Name
                      </span>
                      {order.recipient.firstName} {order.recipient.lastName}
                    </li>
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Payout Reference
                      </span>
                      <span></span>
                      {order.reference}
                    </li>
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Payout Status
                      </span>
                      {enumToReadable(order.status)}
                    </li>
                  </ul>
                </div>
                <div className="flex justify-center mt-8">
                  <Button text={"Back"} onClick={handleGoBack} />
                </div>
              </>
            )
          }
        />
      </div>
    </PageWrapper>
  );
};

export default ViewExchange;
