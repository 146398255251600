import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Card, Loader} from "../../../../components";
import {AddOutline} from "react-ionicons";
import {useToasts} from "react-toast-notifications";
import {getFeeConfigs, postFeeConfigs} from "../../../../api";
import {useAuth} from "../../../../hooks";
import {FeeConfiguration, TradeType,} from "@nestcoinco/onboard-api-gateway-api-client";
import FeeModal, {IFormProps, Mode} from "./FeeModal";

interface IFee {
  product: string;
}

const Fee = ({ product }: IFee) => {
  const [mode, setMode] = useState(Mode.CREATE);
  const [data, setData] = useState<FeeConfiguration[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentFeePercent, setCurrentFeePercent] = useState<number>(0);
  const [currentFeeType, setCurrentFeeType] = useState<TradeType | undefined>()

  const { authToken } = useAuth();
  const { addToast } = useToasts();

  const onrampFee = useMemo(() => {
    return data?.find(c => c.tradeType === TradeType.ONRAMP && c.product === product);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  const offrampFee = useMemo(() => {
    return data?.find(c => c.tradeType === TradeType.OFFRAMP && c.product === product);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const fetchFeeConfigs = useCallback( async () => {
    setIsLoading(true);
    return getFeeConfigs(authToken!, product)
      .then(data => {
        setData(data?.feeConfigs);
      })
      .catch (error => {
        addToast(`Error fetching fee config: ${error.message}`, { appearance: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  useEffect(() => {
    fetchFeeConfigs();
  }, [fetchFeeConfigs]);

  const closeModal = () => {
    setShowModal(false);
    setCurrentFeePercent(0);
    setCurrentFeeType(undefined)
  };

  const onEdit = (fee: FeeConfiguration) => {
    setMode(Mode.UPDATE);
    setCurrentFeeType(fee.tradeType)
    setCurrentFeePercent(fee.tradePercentage);
    setShowModal(true);
  };

  const onCreate = (type: TradeType) => {
    setMode(Mode.CREATE);
    setCurrentFeeType(type)
    setCurrentFeePercent(0);
    setShowModal(true);
  }

  const handleSubmit = async (values: IFormProps) => {
    setIsUpdating(true);
    try {
      setIsUpdating(true);
      await postFeeConfigs(authToken!, {
        product,
        tradePercentage: values.tradePercentage,
        tradeType: values.tradeType as TradeType,
      });
      addToast(
        `fee successfully ${mode === Mode.CREATE ? "created" : "updated"}`,
        { appearance: "success" }
      );
      closeModal();
      await fetchFeeConfigs();
    } catch (error) {
      addToast(`Error ${mode === Mode.CREATE ? "creating" : "updating"} fee`, {
        appearance: "error",
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Card>
      <h1 className="mb-6 font-semibold text-lg">Partner fee preference</h1>
      {isLoading ? (
        <div className="h-400 grid place-items-center">
          {" "}
          <Loader color="blue-600" />
        </div>
      ) : (
        <>
          {offrampFee ? (
            <>
              <h3 className="mb-2 font-medium">Offramp fee</h3>
              <div className="flex items-center gap-5">
                <div className="flex gap-10">
                  <p>
                    <span className="block text-[10px] text-gray-600 mb-1">
                      <span className="font-medium">Trade percentage</span>{" "}
                      (percentage of trade to be used as fee)
                    </span>
                    <span className="text-center text-sm">
                      {offrampFee.tradePercentage}%
                    </span>
                  </p>
                </div>
                <button
                  className="text-[12px] text-green-600"
                  onClick={() => onEdit(offrampFee)}
                >
                  Edit
                </button>
              </div>
            </>
          ) : null}

          {onrampFee ? (
            <>
              <h3 className="mt-14 mb-2 font-medium">Onramp fee</h3>
              <div className="flex items-center gap-5">
                <div className="flex gap-10">
                  <p>
                    <span className="block text-[10px] text-gray-600 mb-1">
                      <span className="font-medium">Trade percentage</span>{" "}
                      (percentage of trade to be used as fee)
                    </span>
                    <span className="text-center text-sm">
                      {onrampFee.tradePercentage}%
                    </span>
                  </p>
                </div>
                <button
                  className="text-[12px] text-green-600"
                  onClick={() => onEdit(onrampFee)}
                >
                  Edit
                </button>
              </div>
            </>
          ) : null}
        </>
      )}
      {(!onrampFee || !offrampFee) && !isLoading ? (
        <div className="w-full h-[200px] flex justify-center items-center">
          <Button
            text="Create Offramp Fee"
            variant="text"
            hidden={!!offrampFee}
            icon={<AddOutline color="inherit" />}
            onClick={() => onCreate(TradeType.OFFRAMP)}
          />
          <Button
            text="Create Onramp Fee"
            variant="text"
            hidden={!!onrampFee}
            icon={<AddOutline color="inherit" />}
            onClick={() => onCreate(TradeType.ONRAMP)}
          />
        </div>
      ) : null}
      <FeeModal
        show={showModal}
        mode={mode}
        initialValues={{
          tradeType: currentFeeType,
          tradePercentage: currentFeePercent,
        }}
        onSubmit={handleSubmit}
        closeModal={closeModal}
        loading={isUpdating}
      />
    </Card>
  );
};

export default Fee;
