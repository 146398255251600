import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Button, DropDown, Input } from "../../../components";
import { DropdownItem } from "../../../components/Dropdown";
import PageWrapper from "../../../components/PageWrapper";
import { useAccounts, useConfig, useSigner } from "../../../hooks";
import { newAccountValidationSchema } from "../../../schemas/accounts";

const NewLiquidityPool = () => {
  const { networks } = useConfig();
  const { create } = useAccounts();
  const { addToast } = useToasts();
  const { fetchAll, signers } = useSigner();
  const navigate = useNavigate();

  const [selectedNetwork, setSelectedNetwork] = useState<DropdownItem>();

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  const _networks = useMemo(() => {
    return (networks || []).map(({ networkId, name }) => ({
      value: networkId,
      text: name,
      icon: "",
    }));
  }, [networks]);

  const initialValues = {
    address: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: newAccountValidationSchema,
    onSubmit: async (values) => {
      const response = await create(selectedNetwork?.value!, values.address);
      if (response?.error) {
        return addToast(response.error.message, { appearance: "error" });
      }
      addToast("New liquidity account created successfully", {
        appearance: "success",
      });
      navigate("/liquidity-pools");
    },
  });

  useEffect(() => {
    if (!selectedNetwork) return;
    const __signer = (signers ?? []).find(
      (signer) => signer.networkId === selectedNetwork?.value
    )?.address;
    formik.setFieldValue("address", __signer ?? "", !!__signer);
    if (!__signer) {
      formik.setFieldError(
        "address",
        `${selectedNetwork?.text} signer not found. Please add signer first.`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signers, selectedNetwork]);

  return (
    <PageWrapper title="Create new liquidity account">
      <form
        onSubmit={formik.handleSubmit}
        className="w-full bg-white rounded shadow mt-9 py-7"
      >
        <div className="mt-8 px-7">
          <div className="grid items-center lg:grid-cols-8 md:grid-cols-8 grid-cols-12 mt-10 justify-items-between gap-2">
            <p className="text-base font-medium leading-normal text-gray-600 lg:col-span-2 md:col-span-2 col-span-12 ">
              Network
            </p>

            <div className="lg:col-span-6 md:col-span-6 col-span-12 max-w-[712px] w-full">
              <DropDown
                items={_networks}
                value={selectedNetwork}
                setValue={(item) => setSelectedNetwork(item)}
                variant="outline"
                placeholder="Select a Network"
              />
            </div>
          </div>
          <div className="grid items-center lg:grid-cols-8 md:grid-cols-8 gap-4 grid-cols-12 mt-10 justify-items-between ">
            <p className="text-base font-medium leading-normal text-gray-600 lg:col-span-2 md:col-span-2 col-span-12">
              Liquidity Accounts Signer
            </p>
            <div className="lg:col-span-6 md:col-span-6 col-span-12 max-w-[712px] w-full">
              <Input
                readOnly
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                required
                type="string"
                placeholder="Address"
                error={formik.errors.address}
              />
            </div>
          </div>
        </div>
        <hr className="bg-slate-100 h-[1px] w-full mt-10" />
        <div className="px-7 w-full flex flex-wrap items-center lg:justify-end md:justify-end justify-center gap-4 pt-10">
          <Button
            text="Create"
            type="submit"
            disabled={!selectedNetwork || !formik.isValid}
            loading={formik.isSubmitting}
          />
        </div>
      </form>
    </PageWrapper>
  );
};

export default NewLiquidityPool;
