import {
  LedgerTransactionsList,
  LedgerTransaction,
  TxTypeTxTypeEnum,
} from "@nestcoinco/onboard-api-gateway-api-client";

import ApiClient, { getTokenHeader } from "./client";

export const getTransactions = async (
  authToken: string,
  transactionType: TxTypeTxTypeEnum[],
  size: number,
  page: number,
  currencies?: string[]
): Promise<LedgerTransactionsList> => {
  const { data } =
    await ApiClient.businessTransactions.getUserLedgerTransactions(
      size,
      page,
      transactionType,
      currencies,
      getTokenHeader(authToken)
    );

  return data;
};

export const getSingleTransaction = async (
  authToken: string,
  transactionId: string
): Promise<LedgerTransaction> => {
  const { data } =
    await ApiClient.businessTransactions.getUserLedgerTransactionByRef(
      transactionId,
      getTokenHeader(authToken)
    );

  return data;
};
