import {
  AccountTransactionTransactionTypeEnum as AccountTransactionType,
  LedgerTransactionsList,
} from "@nestcoinco/onboard-api-gateway-api-client";
import React, { useEffect, useMemo, useState } from "react";
import { getTransactions } from "../../api/transactions";
import { Table, DropDown } from "../../components";
import WalletCardsContainer from "../../components/Cards/WalletCard/WalletCardsContainer";
import PageWrapper from "../../components/PageWrapper";
import {
  transactionColumn,
  typeFilterItems,
} from "../../constants/transaction";
import {useAuth, useErrorHandler} from "../../hooks";

const pageSize = 10;

const Transactions = () => {
  const { toastError } = useErrorHandler();
  const { authToken, verified } = useAuth();
  const [currentPage] = useState<number>(1);
  const [typeFilter, setTypeFilter] = useState<AccountTransactionType | any>(
    typeFilterItems[0]
  );
  const [transactions, setTransactions] = useState<
    LedgerTransactionsList | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);

  const transactionData = useMemo(
    () => transactions?.records ?? [],
    [transactions]
  );

  const columns = useMemo(() => transactionColumn(), []);

  useEffect(() => {
    if (!authToken || !verified) return;
    const filter = typeFilter.filter;
    const type = filter ? [filter] : undefined;
    setIsLoading(true);
    getTransactions(authToken, type!, pageSize, currentPage)
      .then(setTransactions)
      .catch(toastError)
      .finally(() => {
        setIsLoading(false);
      });
  }, [typeFilter, currentPage, toastError, authToken, verified]);

  return (
    <PageWrapper title="Wallet">
      <section className="bg-gray-white rounded-[10px] p-6 py-10 mb-16">
        <WalletCardsContainer />
      </section>

      <div className="flex justify-end mb-6 p-4 px-6 rounded-md bg-white">
        <DropDown
          className="!w-[200px] whitespace-nowrap"
          items={typeFilterItems}
          value={typeFilter}
          setValue={setTypeFilter}
        />
      </div>
      <Table
        columns={columns}
        data={transactionData}
        loading={isLoading}
        noResultMessage="No transactions found."
        title="Transaction History"
        showFilter={false}
      />
    </PageWrapper>
  );
};

export default Transactions;
