import {
  ONBOARD_DEFI_PRODUCT,
  ONBOARD_NON_DEFI_PRODUCT,
  OPN_PRODCT,
} from "../../constants/products";
import useAuth from "../useAuth";

const useProductAccess = () => {
  const { business } = useAuth();

  const hasOpnProduct = business?.enabledProducts?.includes(OPN_PRODCT);
  const hasNonDefiOnboardProduct = business?.enabledProducts?.includes(
    ONBOARD_NON_DEFI_PRODUCT
  );
  const hasDefiOnboardProduct =
    business?.enabledProducts?.includes(ONBOARD_DEFI_PRODUCT);

  return { hasDefiOnboardProduct, hasNonDefiOnboardProduct, hasOpnProduct };
};

export default useProductAccess;
