import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { AuthCard, Button, PinInput } from "../../../components";
import { useAuth, useHasSetup2fa } from "../../../hooks";

const TwoFA = () => {
  const { loading, twoFactorAuthenticate } = useAuth();
  const { loading: hasSetup2faLoading, hasSetup2fa } = useHasSetup2fa();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasSetup2faLoading) return;

    if (!hasSetup2fa) {
      navigate("/auth/2fa-setup");
    }
  }, [hasSetup2fa, hasSetup2faLoading, navigate]);

  const [pin, setPin] = useState("");

  const handleSubmit = async () => {
    const response = await twoFactorAuthenticate(pin);

    if (response?.error) {
      addToast(response.error.message, { appearance: "error" });
      return;
    }
    navigate("/");
  };

  return (
    <AuthCard
      title="2FA"
      subtitle="Please enter the 2FA code from your authenticator app"
      loading={hasSetup2faLoading}
    >
      <div>
        <div className="my-8">
          <PinInput value={pin} onPinChange={(v) => setPin(v)} />
        </div>
        <Button
          text="Authenticate"
          loading={loading}
          disabled={pin.length < 6}
          className="mt-4 mb-1"
          fullWidth
          onClick={handleSubmit}
        />
      </div>
    </AuthCard>
  );
};

export default TwoFA;
