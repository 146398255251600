import {
  ExchangeOrderDTO,
  ExchangeOrdersResponse,
  GetExchangeOrdersStatusesEnum,
  GetExchangeOrdersTransactionTypeEnum,
  OrderQueryStatus,
  OrderTransactionList,
  OrdersSvcCreateOrderRequest,
  OrdersSvcOrderResponse,
  QuoteRequestDto,
  OrderType as RateOrderType,
} from "@nestcoinco/onboard-api-gateway-api-client";
import ApiClient, { getTokenHeader } from "./client";

export interface IOrderFilter {
  from?: string;
  to?: string;
  size?: number;
  page?: number;
  transactionType?: GetExchangeOrdersTransactionTypeEnum;
  statuses?: GetExchangeOrdersStatusesEnum[];
  asset?: string;
  fiat?: string;
  networkId?: string;
}

export interface IOPNOrderFilter {
  size?: number;
  page?: number;
  status?: OrderQueryStatus;
}

export const getOrders = async (
  authToken: string,
  filter: IOrderFilter = {}
): Promise<ExchangeOrdersResponse> => {
  const { data } = await ApiClient.exchange.order.getExchangeOrders(
    filter?.from,
    filter?.to,
    filter?.size,
    filter?.page,
    filter?.transactionType,
    filter?.statuses,
    filter?.asset,
    filter?.fiat,
    filter?.networkId,
    getTokenHeader(authToken)
  );

  return data;
};

export const getOrderById = async (
  authToken: string,
  orderId: string
): Promise<ExchangeOrderDTO> => {
  const { data } = await ApiClient.exchange.order.getExchangeOrderById(
    orderId,
    getTokenHeader(authToken)
  );
  return data;
};

export const getClientOrderById = async (
  authToken: string,
  orderId: string
): Promise<OrdersSvcOrderResponse> => {
  const { data } = await ApiClient.ordersClient.getClientOrder(
    orderId,
    getTokenHeader(authToken)
  );
  return data;
};

export const getOPNOrders = async (
  authToken: string,
  { status, page, size }: IOPNOrderFilter
): Promise<OrderTransactionList> => {
  const { data } = await ApiClient.order.orders.getClientOrders(
    status,
    page,
    size,
    getTokenHeader(authToken)
  );
  return data;
};

export const getOPNOrder = async (
  authToken: string,
  txRef: string
): Promise<OrdersSvcOrderResponse> => {
  const { data } = await ApiClient.order.orders.getClientOrder(
    txRef,
    getTokenHeader(authToken)
  );
  return data;
};

export const getQuote = async (token: string, payload: QuoteRequestDto) => {
  const { data } = await ApiClient.order.quotes.requestQuote(
    payload,
    getTokenHeader(token)
  );
  return data;
};

export const getReferenceRate = async (
  token: string,
  currency: string,
  orderType: RateOrderType
) => {
  const { data } = await ApiClient.order.quotes.getReferenceRate(
    currency,
    orderType,
    getTokenHeader(token)
  );
  return data;
};

export const createClientOrder = async (
  token: string,
  payload: OrdersSvcCreateOrderRequest
) => {
  const { data } = await ApiClient.order.orders.createClientOrder(
    payload,
    getTokenHeader(token)
  );
  return data;
};
