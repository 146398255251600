import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Copier,
  Input,
  Loader,
  TooltipWithCTA,
} from "../../../components";
import {useAuth, useErrorHandler, useHasSetup2fa} from "../../../hooks";
import { twoFAVerificationSchema } from "../../../schemas/auth";
import { useEffect, useState } from "react";
import { TwoFaSecretDto } from "@nestcoinco/onboard-api-gateway-api-client";
import { generate2faSecretForUser } from "../../../api";
import {
  twoFAAuthenticatorAppTooltipCTAUrl,
  twoFAAuthenticatorAppTooltipDescription,
} from "../../../constants/auth";
import { AuthType } from "../../../providers/AuthProvider";

const VerifyOtp = () => {
  const { verify2faSecret, authToken, authType } = useAuth();
  const { loading: hasSetup2faLoading, hasSetup2fa } = useHasSetup2fa();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [twoFAData, setTwoFAData] = useState<TwoFaSecretDto>();
  const [_loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();
  const { toastError } = useErrorHandler();
  useEffect(() => {
    if (hasSetup2faLoading) return;

    if (!(authType === AuthType.PARTIAL && authToken)) {
      return navigate("/auth/login");
    }

    if (hasSetup2fa) {
      navigate("/auth/2fa");
    }
  }, [hasSetup2fa, hasSetup2faLoading, authToken, authType, navigate]);

  const initialValues = {
    twoFACode: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: twoFAVerificationSchema,
    onSubmit: async (values) => {
      try {
        const response = await verify2faSecret(values.twoFACode);
        if (response?.error) {
          const error = response.error.message;
          return addToast(error, { appearance: "error" });
        }
        navigate("/", { state: { from: "2faSetup" } });
      } catch (e) {
        console.log(e);
      }
    },
  });

  useEffect(() => {
    if (authToken) {
      generate2faSecretForUser(authToken!)
        .then(setTwoFAData)
        .catch(e => toastError(e, setError))
        .finally(() => setLoading(false));
    }
  }, [authToken, toastError]);

  const handleTooltipCTA = () => {
    window.open(twoFAAuthenticatorAppTooltipCTAUrl, "_blank");
  };

  return (
    <div className="w-full">
      <div className="max-w-[906px] flex flex-col gap-6 md:flex-row justify-between md:items-center">
        <div>
          <h1 className="font-semibold text-[28px] pb-[63px]">
            Complete set up of your profile
          </h1>

          <h3 className="text-lg font-semibold mb-5 leading-8">
            <span className="text-primary-main">STEP 1:</span> Add 2FA
            authentication
          </h3>

          <ul className="list-disc ml-4 mb-7">
            <li className="leading-8">
              Use the{" "}
              <TooltipWithCTA
                title="What is an Authenticator app"
                description={twoFAAuthenticatorAppTooltipDescription}
                ctaText="Read more"
                cta={handleTooltipCTA}
              >
                Google Authenticator app
              </TooltipWithCTA>{" "}
              to scan this QR code <span className="font-semibold">OR</span>
            </li>
            <li className="leading-8">
              Enter the 6-digit setup key below the QR code
            </li>
          </ul>
          <form onSubmit={formik.handleSubmit}>
            <Input
              label="Enter OTP"
              name="twoFACode"
              value={formik.values.twoFACode}
              onChange={formik.handleChange}
              required
              error={formik.errors.twoFACode}
              variant="dark"
            />
            {error && formik.isValid ? (
              <p className="text-red-500 text-xs italic">{error}</p>
            ) : null}
            <Button
              text={formik.isSubmitting ? "Verifying OTP..." : "Verify OTP"}
              type="submit"
              className="mt-6 mb-1"
              fullWidth
              loading={formik.isSubmitting}
              disabled={
                !!error || !formik.isValid || !formik.values.twoFACode
              }
            />
          </form>
        </div>
        <div className="-order-1 md:order-1">
          {_loading ? (
            <div className="flex justify-center items-center h-12 min-w-[199px] max-w-[199px]">
              <Loader color="blue-600" />
            </div>
          ) : error ? (
            <div className="flex justify-center items-center h-12">
              Something went wrong. Please {"  "}
              <button
                className="btn btn-text text-blue-600 ml-1"
                onClick={() => window.history.go()}
              >
                {" "}
                refresh
              </button>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <div className="min-w-[199px] p-5 bg-gray-300 min-h-[199px] rounded-[10px] w-min">
                <img
                  className="object-cover"
                  src={twoFAData?.qrCode}
                  alt="OTP QRcode"
                />
              </div>
              <p className="pt-4 text-sm flex gap-1">
                Setup key:{" "}
                <span className="font-semibold flex items-center gap-1">
                  {twoFAData?.secret} <Copier text={twoFAData?.secret!} />
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default VerifyOtp;
