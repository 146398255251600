import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, DropDown } from "..";
import useWithdrawal from "../../hooks/useWithdrawal";
import { DropdownItem } from "../Dropdown";
import { QuotedRateType } from "../../providers/WithdrawalProvider";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import { p2pwalletRecipientSchema } from "../../schemas/withdrawal";

interface IState {
  usd: number;
  ngn: number;
  opnQuotedRate: QuotedRateType;
}

const P2PWallet = () => {
  const state = useLocation().state as IState;
  const { fetchProviders } = useWithdrawal();

  const initialValues = {
    accountId: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  };
  const [selectedProvider, setSelectedProvider] = useState<DropdownItem>();
  const [providerList, setProviderList] = useState<DropdownItem[]>([]);

  useEffect(() => {
    if (!state.usd) {
      navigate("/withdrawal/onboard-fast");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues,

    validationSchema: p2pwalletRecipientSchema,
    onSubmit: async (values) => {},
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    formik.setFieldValue(name, value);
  };

  const handleInitiatePayout = async () => {
    const errors = await formik.validateForm();
    if (!formik.isValid) {
      formik.setErrors(errors);
      return;
    }
    const recipientDetails = formik.values;
    navigate("/withdrawal/onboard-fast/confirm", {
      state: {
        data: {
          quoteId: state.opnQuotedRate.id,
          amount: state.usd,
          reference: uuidv4(),
          type: "SELL",
          recipient: {
            email: recipientDetails.email,
            phoneNumber: recipientDetails.phone,
            firstName: recipientDetails.firstName,
            lastName: recipientDetails.lastName,
            providerId: selectedProvider?.value,
            accountId: recipientDetails.accountId,
            recipientType: "P2PWalletRecipient",
          },
        },
        ...state,
        selectedProvider,
      },
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchProviders()
      .then((providers) => {
        const providerMapper = providers?.reduce((list, provider) => {
          // if (
          //   provider.supportedPaymentChannels.includes(
          //     PayoutsSvcProviderPaymentChannel.P2P_WALLET
          //   )
          // ) {
          //   list.push({
          //     value: provider.id,
          //     text: provider.name,
          //     icon: "",
          //   } as DropdownItem);
          // }
          return list;
        }, [] as DropdownItem[]);

        setProviderList(providerMapper!);
      })
      .finally(() => {
        setIsLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-center w-full">
      <Card
        title="Enter account details"
        headingClassNames="mb-8 text-center"
        classNames="py-12 px-6 mt-20 min-w-[400px] max-w-[500px] justify-center bg-white shadow-md rounded-md"
        children={
          <>
            <div className="w-full">
              <div className="w-full border rounded my-6 bg-gray-200">
                <DropDown
                  items={providerList}
                  value={selectedProvider}
                  setValue={(item) => setSelectedProvider(item)}
                  variant="text"
                  placeholder="Select P2P provider"
                />
              </div>
              <div className="p-3" />
              <input
                className={
                  "appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                }
                type="text"
                pattern="^[0-9]{10}$"
                placeholder="Account ID"
                name="accountId"
                onChange={handleInputChange}
                disabled={!selectedProvider}
                value={formik.values.accountId}
              />
              <p className="text-[red] text-[12px]">
                {formik.errors.accountId}
              </p>
              <div className="p-3" />
              <input
                className={
                  "appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                }
                type="text"
                placeholder="First Name"
                name="firstName"
                onChange={handleInputChange}
                value={formik.values.firstName}
              />
              <p className="text-[red] text-[12px]">
                {formik.errors.firstName}
              </p>
              <div className="p-3" />
              <input
                className={
                  "appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                }
                type="text"
                placeholder="Last Name"
                name="lastName"
                onChange={handleInputChange}
                value={formik.values.lastName}
              />
              <p className="text-[red] text-[12px]">{formik.errors.lastName}</p>
              <div className="p-3" />
              <input
                className={
                  "appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                }
                type="email"
                placeholder="Email"
                name="email"
                onChange={handleInputChange}
                value={formik.values.email}
              />
              <p className="text-[red] text-[12px]">{formik.errors.email}</p>
              <div className="p-3" />
              <input
                className={
                  "appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                }
                type="tel"
                name="phone"
                placeholder="Phone number"
                onChange={handleInputChange}
                value={formik.values.phone}
              />
              <p className="text-[red] text-[12px]">{formik.errors.phone}</p>
              <div className="p-3" />
              <Button
                loading={isLoading}
                className="w-full"
                text={"Continue"}
                onClick={handleInitiatePayout}
              />
            </div>
          </>
        }
      />
    </div>
  );
};

export default P2PWallet;
