import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card } from "../../../components";
import PageWrapper from "../../../components/PageWrapper";
import useWithdrawal from "../../../hooks/useWithdrawal";
import {
  BankAccountRecipient,
  NaijaAccountValidationResponse,
  OrdersSvcCreateOrderRequest,
  P2PWalletRecipient,
} from "@nestcoinco/onboard-api-gateway-api-client";
import { QuotedRateType } from "../../../providers/WithdrawalProvider";
import dayjs from "dayjs";
import { ArrowBackOutline } from "react-ionicons";
import { DropdownItem } from "../../../components/Dropdown";

type StateType = {
  data: {
    quoteId: string;
    amount: number;
    reference: string;
    type: string;
    recipient: BankAccountRecipient | P2PWalletRecipient;
  };
  usd: number;
  ngn: number;
  opnQuotedRate: QuotedRateType;
  verifiedAccount: NaijaAccountValidationResponse;
  selectedProvider: DropdownItem;
};

const ConfirmOnboardFast = () => {
  const location = useLocation();
  const { data, ngn, verifiedAccount, selectedProvider } =
    location.state as StateType;
  const { opnQuotedRate, selectedMethod, createOrder } = useWithdrawal();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (!data || !opnQuotedRate) {
      navigate("/withdrawal/onboard-fast");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const onSubmit = async () => {
    setIsLoading(true);
    const payload: OrdersSvcCreateOrderRequest = {
      twoFaCode: otp,
      ...data,
    } as unknown as OrdersSvcCreateOrderRequest;
    const createOrderResponse = await createOrder(payload);

    if (createOrderResponse?.success) {
      navigate("/withdrawal/success", {
        replace: true,
        state: {
          message:
            "Withdrawal Order has been created and will be fulfilled soon",
        },
      });
    }
    setIsLoading(false);
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setOtp(value);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <PageWrapper title="Confirm Withdrawal">
      <p className="mt-4 flex cursor-pointer" onClick={handleBack}>
        <ArrowBackOutline /> Back{" "}
      </p>
      <div className="flex justify-center w-full">
        <Card
          title="Confirm OnboardFast Withdrawal"
          headingClassNames="mb-8 text-center"
          classNames="py-12 px-6 mt-20 min-w-[400px] max-w-[500px] justify-center bg-white shadow-md rounded-md"
          children={
            <>
              <div className="w-full">
                <div className="p-3" />

                <ul className="my-3">
                  <li className="flex gap-6 py-2">
                    <span className="font-semibold basis-40 inline-block">
                      Date
                    </span>
                    {dayjs().format("MMMM D, YYYY")}
                  </li>
                  <li className="flex gap-6 py-2">
                    <span className="font-semibold basis-40 inline-block">
                      Amount
                    </span>
                    USD {data?.amount}
                  </li>
                  <li className="flex gap-6 py-2">
                    <span className="font-semibold basis-40 inline-block">
                      Local Amount
                    </span>
                    NGN {ngn}
                  </li>
                  <li className="flex gap-6 py-2">
                    <span className="font-semibold basis-40 inline-block">
                      Rate
                    </span>
                    NGN{opnQuotedRate?.rate}/USD
                  </li>
                  <li className="flex gap-6 py-2">
                    <span className="font-semibold basis-40 inline-block">
                      Payment Method
                    </span>
                    {selectedMethod?.text}
                  </li>
                  {verifiedAccount && (
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Payment Details
                      </span>
                      <span>
                        {verifiedAccount.bankName}
                        <br />
                        {verifiedAccount.accountNumber}
                        <br />
                        {verifiedAccount.accountName}
                      </span>
                    </li>
                  )}
                  {selectedMethod?.value === "P2P_WALLET" ? (
                    <>
                      <li className="flex gap-6 py-2">
                        <span className="font-semibold basis-40 inline-block">
                          Provider Name
                        </span>
                        {selectedProvider.text}
                      </li>
                      <li className="flex gap-6 py-2">
                        <span className="font-semibold basis-40 inline-block">
                          Account ID
                        </span>
                        {(data.recipient as P2PWalletRecipient).accountId}
                      </li>
                    </>
                  ) : null}
                </ul>
                <div className="p-3" />
                <p className="text-center">Enter OTP from Authentication App</p>
                <div className="p-3" />
                <input
                  className={
                    "appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white tracking-[1.5rem] text-center"
                  }
                  type="text"
                  placeholder="123456"
                  value={otp}
                  onChange={handleOtpChange}
                />
                <div className="p-3" />

                <div className="p-3" />
                <Button
                  loading={isLoading}
                  className="w-full"
                  text={"Confirm Withdrawal"}
                  disabled={!otp}
                  onClick={onSubmit}
                />
              </div>
            </>
          }
        />
      </div>
    </PageWrapper>
  );
};

export default ConfirmOnboardFast;
