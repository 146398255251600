import { useFormik } from "formik";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { CloseCircleOutline } from "react-ionicons";
import { Button } from "../../../../components";
import { feeConfigSchema } from "../../../../schemas/fees";
import { CountInput } from "../../../../components/Input/CountInput";
import { TradeType } from "@nestcoinco/onboard-api-gateway-api-client";

export interface IFormProps {
  tradeType: string;
  tradePercentage: number;
}

export enum Mode {
  "UPDATE" = "UPDATE",
  "CREATE" = "CREATE",
}

interface IFeeModal {
  show: boolean;
  loading?: boolean;
  onSubmit: (values: IFormProps) => void;
  mode: Mode;
  initialValues: { tradeType?: string; tradePercentage: number };
  closeModal: () => void;
}

const FeeModal: React.FC<IFeeModal> = ({
  show,
  loading,
  onSubmit,
  mode,
  initialValues,
  closeModal
}) => {
  const isCreateFeeMode = mode === Mode.CREATE;
  const _initialValues: IFormProps = {
    tradeType: initialValues.tradeType || TradeType.ONRAMP,
    tradePercentage: initialValues.tradePercentage || 0,
  };

  const formik = useFormik({
    initialValues: _initialValues,
    validationSchema: feeConfigSchema,
    onSubmit,
  });

  const handleClose = () => {
    closeModal();
    formik.resetForm();
  };

  const handleIncrease = () => {
    formik.setFieldValue(
      "tradePercentage",
      +formik.values.tradePercentage + 0.5
    );
  };
  const handleDecrease = () => {
    formik.setFieldValue(
      "tradePercentage",
      +formik.values.tradePercentage - 0.5
    );
  };

  useEffect(() => {
    if (_initialValues.tradePercentage) {
      formik.setFieldValue("tradePercentage", _initialValues.tradePercentage, true);
    }
    if (_initialValues.tradeType) {
      formik.setFieldValue("tradeType", _initialValues.tradeType, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_initialValues.tradePercentage, _initialValues.tradeType, mode]);


  return show ? (
    <motion.main
      onClick={handleClose}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="h-screen w-screen backdrop-blur-md bg-black-200/30 place-items-center z-100 fixed grid -top-0 right-0 bottom-0"
    >
      <div className="inset-0 absolute" />
      <motion.article
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.35 }}
        exit={{ scale: 0 }}
        className="bg-white rounded-sm border border-grey flex flex-col items-center w-11/12 sm:w-3/4 max-w-[450px] pt-16 pb-9 relative px-5 shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="appearance-none w-auto h-auto absolute top-6 right-6"
          onClick={handleClose}
          autoFocus
        >
          <CloseCircleOutline />
        </button>
        <div className="mt-4 w-full max-w-[300px]">
          <h2 className="w-full text-xl font-semibold text-center mx-auto mt-1 mb-10">
            {isCreateFeeMode ? "Create" : "Update"}{" "}
            {initialValues.tradeType?.toLowerCase()} fee preference
          </h2>
          <CountInput
            label="Enter trade percentage"
            name="tradePercentage"
            onDecrease={handleDecrease}
            onIncrease={handleIncrease}
            inputMode="decimal"
            onChange={formik.handleChange}
            value={formik.values.tradePercentage}
            error={formik.errors.tradePercentage}
          />
          <div className="w-full mt-4">
            <Button
              type="button"
              text="Submit"
              fullWidth
              onClick={() => formik.handleSubmit()}
              loading={loading ?? formik.isSubmitting}
              disabled={
                (_initialValues.tradePercentage === formik.values.tradePercentage && mode === Mode.UPDATE) || // 0 is allowed for create
                formik.isSubmitting ||
                !formik.isValid ||
                loading
              }
            />
          </div>
        </div>
      </motion.article>
    </motion.main>
  ) : null;
};

export default FeeModal;
