import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import PageWrapper from "../../components/PageWrapper";
import { useAccounts, useAuth, useConfig, useSigner } from "../../hooks";
import useApiKeys from "../../hooks/useApiKeys";
import useUrls from "../../hooks/useUrls";
import {
  BriefcaseOutline,
  CashOutline,
  CheckmarkDoneOutline,
  FlashOutline,
  KeyOutline,
  PersonOutline,
  SyncOutline,
} from "react-ionicons";

import { toCommaValue } from "../../lib";
import { getOPNOrders, getOrders } from "../../api/order";
import { getTotalBalances } from "../../lib/transactions";
import {
  GetExchangeOrdersStatusesEnum,
  OrderQueryStatus,
} from "@nestcoinco/onboard-api-gateway-api-client";
import { Loader } from "../../components";
import useProductAccess from "../../hooks/useProductAccess/useProductAccess";

const Dashboard = () => {
  const { signers, loading: loadingSigners } = useSigner();
  const { apiKeys } = useApiKeys();
  const { authToken, verified } = useAuth();
  const { hasDefiOnboardProduct, hasOpnProduct, hasNonDefiOnboardProduct } =
    useProductAccess();
  const { cexConfig, loadCexConfigs } = useConfig();
  const { autoExecUrl, authorizationUrl } = useUrls();
  const {
    totalUsdBalance,
    accounts,
    customerAccounts,
    loadingCustomerAccounts,
  } = useAccounts();
  const accountsBalances = getTotalBalances(customerAccounts! || []);
  const currency = Object.keys(accountsBalances)[0];
  const [loadingData, setLoadingData] = useState(false);
  const [isLoadingOpnOrders, setIsLoadingOpnOrders] = useState(false);
  const [ongoingOrdersTotal, setOngoingOrdersTotal] = useState<number>(0);
  const [completedOrdersTotal, setCompletedOrdersTotal] = useState<number>(0);
  const [cancelledOrdersTotal, setCancelledOrdersTotal] = useState<number>(0);
  const completionRate =
    Math.round(
      (completedOrdersTotal / (completedOrdersTotal + cancelledOrdersTotal)) *
        100
    ) || 0;

  const [opnOngoingOrdersTotal, setOpnOngoingOrdersTotal] = useState<number>(0);
  const [opnCompletedOrdersTotal, setOpnCompletedOrdersTotal] =
    useState<number>(0);
  const [opnCancelledOrdersTotal, setOpnCancelledOrdersTotal] =
    useState<number>(0);
  const opnCompletionRate =
    Math.round(
      (opnCompletedOrdersTotal /
        (opnCompletedOrdersTotal + opnCancelledOrdersTotal)) *
        100
    ) || 0;

  const fetchOrdersdata = async () => {
    const [ongoingOrders, cancelledOrders, completedOrders] = await Promise.all(
      [
        await getOrders(authToken!, {
          statuses: [
            GetExchangeOrdersStatusesEnum.PENDING,
            GetExchangeOrdersStatusesEnum.IN_DISPUTE,
            GetExchangeOrdersStatusesEnum.AWAITING_ACCEPTANCE,
            GetExchangeOrdersStatusesEnum.INITIATED,
            GetExchangeOrdersStatusesEnum.DEPOSITED,
          ],
        }),
        await getOrders(authToken!, {
          statuses: [GetExchangeOrdersStatusesEnum.CANCELLED],
        }),
        await getOrders(authToken!, {
          statuses: [
            GetExchangeOrdersStatusesEnum.COMPLETED,
            GetExchangeOrdersStatusesEnum.CONFIRMED,
          ],
        }),
      ]
    );
    setCompletedOrdersTotal(completedOrders.totalOrders!);
    setCancelledOrdersTotal(cancelledOrders.totalOrders!);
    setOngoingOrdersTotal(ongoingOrders.totalOrders!);
  };

  const fetchOpnOrdersdata = async () => {
    const [ongoingOrders, cancelledOrders, completedOrders] = await Promise.all(
      [
        await getOPNOrders(authToken!, {
          status: OrderQueryStatus.ONGOING,
        }),
        await getOPNOrders(authToken!, {
          status: OrderQueryStatus.FAILED,
        }),
        await getOPNOrders(authToken!, {
          status: OrderQueryStatus.COMPLETED,
        }),
      ]
    );
    setOpnCompletedOrdersTotal(completedOrders.totalItems!);
    setOpnCancelledOrdersTotal(cancelledOrders.totalItems!);
    setOpnOngoingOrdersTotal(ongoingOrders.totalItems!);
  };

  const hasAccount = useMemo(
    () => accounts?.length ?? true,
    [accounts?.length]
  );
  const hasSigner = useMemo(() => signers?.length ?? true, [signers?.length]);
  const hasKeys = useMemo(() => apiKeys?.length ?? true, [apiKeys?.length]);
  const hasWebhookSecret = useMemo(
    () => cexConfig?.isWebhookSecretSet ?? true,
    [cexConfig?.isWebhookSecretSet]
  );
  const hasUrls = useMemo(
    () => autoExecUrl !== undefined || authorizationUrl !== undefined,
    [authorizationUrl, autoExecUrl]
  );

  useEffect(() => {
    (async () => {
      if (authToken && verified) {
        loadCexConfigs();
        try {
          setLoadingData(true);
          await fetchOrdersdata();
        } catch (error) {
        } finally {
          setLoadingData(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, loadCexConfigs, verified]);

  useEffect(() => {
    (async () => {
      if (authToken) {
        try {
          setIsLoadingOpnOrders(true);
          await fetchOpnOrdersdata();
        } catch (error) {
        } finally {
          setIsLoadingOpnOrders(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  return (
    <PageWrapper title="Dashboard" hideCrumb>
      {((hasNonDefiOnboardProduct && (!hasAccount || !hasSigner)) ||
        !hasKeys ||
        !hasUrls ||
        !hasWebhookSecret) && (
        <div className="mt-14 shadow-md p-5 rounded-md">
          <p className="font-bold mb-4">Complete Setup</p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-5">
            {!hasKeys && (
              <div className="col-span-1 rounded-lg bg-white shadow-sm py-5 px-8 border border-green-200 text-center">
                <Link to="/api-keys">Setup API Keys</Link>
              </div>
            )}
            {!hasUrls && (
              <div className="col-span-1 rounded-lg bg-white shadow-sm py-5 px-8 border border-green-200 text-center">
                <Link to="/settings?tab=urls">Setup Webhook URLs</Link>
              </div>
            )}
            {!hasWebhookSecret && (
              <div className="col-span-1 rounded-lg bg-white shadow-sm py-5 px-8 border border-green-200 text-center">
                <Link to="/settings?tab=secret">Setup Webhook Secret</Link>
              </div>
            )}
            {hasNonDefiOnboardProduct && !hasSigner && (
              <div className="col-span-1 rounded-lg bg-white shadow-sm py-5 px-8 border border-green-200 text-center">
                <Link to="/signers">Setup Signer</Link>
              </div>
            )}
            {hasNonDefiOnboardProduct && !hasAccount && (
              <div className="col-span-1 rounded-lg bg-white shadow-sm py-5 px-8 border border-green-200 text-center">
                <Link to="/liquidity-pools">Create Pool Account</Link>
              </div>
            )}
          </div>
        </div>
      )}
      {hasDefiOnboardProduct ? (
        <>
          <h2 className="font-semibold mb-4 mt-10">Onboard Connect</h2>
          <div className="grid md:grid-cols-2 gap-3">
            <section className="flex flex-col border-2 rounded-md p-5 bg-white">
              <div className="grid grid-cols-2 justify-items-start gap-x-2 gap-y-6">
                {loadingData ? (
                  <div className="h-full w-full col-span-2 grid place-items-center">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <p className="flex items-start gap-1">
                      <PersonOutline width={"33px"} height={"33px"} />
                      <div className="flex flex-col">
                        <span className="text-xl font-medium">{0}</span>
                        <span className="text-xs text-gray-700">
                          Trading customers
                        </span>
                      </div>
                    </p>
                    <p className="flex items-start gap-1">
                      <SyncOutline width={"33px"} height={"33px"} />
                      <div className="flex flex-col">
                        <span className="text-xl font-medium">
                          {ongoingOrdersTotal || 0}
                        </span>
                        <span className="text-xs text-gray-700">
                          Ongoing orders
                        </span>
                      </div>
                    </p>
                    <p className="flex items-start gap-1">
                      <CheckmarkDoneOutline width={"33px"} height={"33px"} />
                      <div className="flex flex-col">
                        <span className="text-xl font-medium">
                          {completedOrdersTotal || 0}
                        </span>
                        <span className="text-xs text-gray-700">
                          Completed orders
                        </span>
                      </div>
                    </p>
                    <p className="flex items-start gap-1">
                      <FlashOutline width={"33px"} height={"33px"} />
                      <div className="flex flex-col">
                        <span className="text-xl font-medium">
                          {completionRate}%
                        </span>
                        <span className="text-xs text-gray-700">
                          Completion rate
                        </span>
                      </div>
                    </p>
                  </>
                )}
              </div>
              <Link
                className="mt-auto ml-auto pt-4 underline block"
                to="/orders"
              >
                View orders
              </Link>
            </section>
            {hasSigner ? (
              <section className="flex flex-col border-2 rounded-md p-5 bg-white">
                <h2 className="font-semibold mb-4">Liquidity accounts</h2>
                <div className="grid grid-cols-2 justify-items-start gap-x-2 gap-y-6 ">
                  {loadingSigners ? (
                    <div className="h-full w-full col-span-2 grid place-items-center">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <Link to="/signers" className=" flex items-start gap-1">
                        <KeyOutline width={"33px"} height={"33px"} />
                        <div className="flex flex-col">
                          <span className="text-xl font-medium">
                            {signers?.length || 0}
                          </span>
                          <span className="text-xs text-gray-700 underline">
                            Signing addresses
                          </span>
                        </div>
                      </Link>
                      <p className="flex items-start gap-2">
                        <CashOutline width={"33px"} height={"33px"} />
                        <div className="flex flex-col">
                          <span className="text-xl font-medium">
                            {toCommaValue(totalUsdBalance, 2)} USD
                          </span>
                          <span className="text-xs text-gray-700">
                            Aggregate liquidity balance
                          </span>
                        </div>
                      </p>
                      <p className="flex items-start gap-1">
                        <BriefcaseOutline width={"33px"} height={"33px"} />
                        <div className="flex flex-col">
                          <span className="text-xl font-medium">
                            {accounts?.length || 0}
                          </span>
                          <span className="text-xs text-gray-700">
                            Liquidity accounts
                          </span>
                        </div>
                      </p>
                    </>
                  )}
                </div>
                <Link
                  className="mt-auto ml-auto pt-4 underline block"
                  to="/liquidity-pools"
                >
                  View accounts
                </Link>
              </section>
            ) : null}
          </div>
        </>
      ) : null}

      {hasOpnProduct ? (
        <section className="mt-6">
          <h2 className="font-semibold mb-4">OPN</h2>
          <div className="grid md:grid-cols-2 gap-4">
            <div className="flex flex-col border-2 rounded-md p-5 bg-white">
              {loadingCustomerAccounts ? (
                <div className="h-full w-full col-span-2 grid place-items-center">
                  <Loader />
                </div>
              ) : (
                <p className="flex items-start gap-2">
                  <CashOutline width={"33px"} height={"33px"} />
                  <div className="flex flex-col">
                    <span className="text-2xl font-medium">
                      {toCommaValue(
                        accountsBalances[currency]?.availableBalance || 0,
                        2
                      )}{" "}
                      {currency}
                    </span>
                    <span className="text-xs text-gray-700">
                      Wallet balance
                    </span>
                  </div>
                </p>
              )}
              <Link
                className="mt-auto pt-4 underline block text-end"
                to="/wallet"
              >
                View
              </Link>
            </div>
            <div className="grid grid-cols-2 border-2 gap-x-2 gap-y-6 rounded-md p-5 bg-white">
              {isLoadingOpnOrders ? (
                <div className="h-full w-full col-span-2 grid place-items-center">
                  <Loader />
                </div>
              ) : (
                <>
                  <p className="flex items-start gap-1">
                    <CheckmarkDoneOutline width={"33px"} height={"33px"} />
                    <div className="flex flex-col">
                      <span className="text-xl font-medium">
                        {opnCompletedOrdersTotal || 0}
                      </span>
                      <span className="text-xs text-gray-700">
                        Completed trades
                      </span>
                    </div>
                  </p>
                  <p className="flex items-start gap-1">
                    <FlashOutline width={"33px"} height={"33px"} />
                    <div className="flex flex-col">
                      <span className="text-xl font-medium">
                        {opnCompletionRate || 0}%
                      </span>
                      <span className="text-xs text-gray-700">
                        Completion rate
                      </span>
                    </div>
                  </p>
                  <p className="flex items-start gap-1">
                    <SyncOutline width={"33px"} height={"33px"} />
                    <div className="flex flex-col">
                      <span className="text-xl font-medium">
                        {opnOngoingOrdersTotal || 0}
                      </span>
                      <span className="text-xs text-gray-700">
                        Ongoing trades
                      </span>
                    </div>
                  </p>
                </>
              )}
            </div>
          </div>
        </section>
      ) : null}
    </PageWrapper>
  );
};

export default Dashboard;
