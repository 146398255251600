export interface IError {
  message: string;
  code: string | 'UNKNOWN';
  data: Record<string, string[]>;
}

export const formatError = (e: any): IError | undefined => {
  if(!e) return undefined;
  if(typeof e === "string") {
    return {
      message: e,
      code: 'UNKNOWN',
      data: {}
    }
  }
  const message = e.response?.data?.message || e.message;
  const data = e.response?.data?.data || {};
  const code = e.response?.data?.code || 'UNKNOWN'
  return { message, code, data }
};
