import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Button, DropDown, Input, TooltipInfo } from '../../../components';
import { DropdownItem } from '../../../components/Dropdown';
import PageWrapper from '../../../components/PageWrapper';
import { useConfig, useSigner } from '../../../hooks';
import { newSignerValidationSchema } from '../../../schemas/signers';

const NewSigner = () => {
  const { create } = useSigner();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [selectedNetwork, setSelectedNetwork] = useState<DropdownItem>();
  const { networks } = useConfig();

  const _networks = useMemo(
    () =>
      (networks ?? []).map(({ networkId, name }) => ({
        value: networkId,
        text: name,
        icon: '',
      })),
    [networks]
  );

  const initialValues = {
    address: '',
    otp: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: newSignerValidationSchema,
    onSubmit: async (values) => {
      const response = await create(selectedNetwork?.value!, values.address, String(values.otp));
      if(response?.error?.data) {
        for (const [key, msg] of  Object.entries(response.error.data)){
          formik.setFieldError(key, msg[0]);
        }
        return;
      }
      if (response?.error) {
          return addToast(response.error.message, { appearance: 'error' });
      }
      addToast('New Signer added successfully', {
        appearance: 'success',
      });
      navigate('/signers');
    },
  });

  return (
    <PageWrapper title='Add Signer'>
      <form onSubmit={formik.handleSubmit} className='w-full bg-white rounded shadow mt-9 py-7'>
        <div className='mt-8 px-7'>
          <div className='grid items-center lg:grid-cols-8 md:grid-cols-8 grid-cols-12 mt-10 justify-items-between gap-2'>
            <p className='text-base font-medium leading-normal text-gray-600 lg:col-span-2 md:col-span-2 col-span-12 '>
              Network
            </p>

            <div className='lg:col-span-6 md:col-span-6 col-span-12 max-w-[712px] w-full'>
              <DropDown
                items={_networks}
                value={selectedNetwork}
                setValue={(item) => setSelectedNetwork(item)}
                variant='outline'
                placeholder='Select a network'
              />
            </div>
          </div>
          <div className='grid items-center lg:grid-cols-8 md:grid-cols-8 gap-4 grid-cols-12 mt-10 justify-items-between '>
            <p className='text-base font-medium leading-normal text-gray-600 lg:col-span-2 md:col-span-2 col-span-12'>
              Address
            </p>

            <div className='lg:col-span-6 md:col-span-6 col-span-12 max-w-[712px] w-full'>
              <Input
                name='address'
                value={formik.values.address}
                onChange={formik.handleChange}
                required
                type='string'
                placeholder='Address'
                error={formik.errors.address}
              />
            </div>
          </div>
          <div className='grid items-center lg:grid-cols-8 md:grid-cols-8 gap-4 grid-cols-12 mt-10 justify-items-between'>
            <div className='lg:col-span-2 md:col-span-2 col-span-12 text-base font-medium leading-normal text-gray-600 flex items-center '>
              OTP
              <div className='ml-1 mt-1'>
                <TooltipInfo text='You need OTP from your authenticator app to add a new signer' />
              </div>
            </div>

            <div className='lg:col-span-6 md:col-span-6 col-span-12 max-w-[712px] w-full'>
              <Input
                name='otp'
                value={formik.values.otp}
                onChange={formik.handleChange}
                required
                placeholder='OTP'
                type='number'
                className='appearance-none'
                error={formik.errors.otp}
              />
            </div>
          </div>
        </div>
        <hr className='bg-slate-100 h-[1px] w-full mt-10' />
        <div className='px-7 w-full flex flex-wrap items-center lg:justify-end md:justify-end justify-center gap-4 pt-10'>
          <Button
            text='Add'
            type='submit'
            disabled={!selectedNetwork || !formik.isValid}
            loading={formik.isSubmitting}
          />
        </div>
      </form>
    </PageWrapper>
  );
};

export default NewSigner;
