import React, {useCallback} from "react";
import {formatError} from "../../api";
import {useToasts} from "react-toast-notifications";

const useErrorHandler = () => {
  const { addToast } = useToasts()

  const toastError = useCallback(
    (e: any, setError?: React.Dispatch<React.SetStateAction<string | undefined>>) => {
      const error = formatError(e)?.message;
      error && addToast(error, { appearance: 'error' });
      error && setError && setError(error);
    },
    [addToast]
  );

  return { toastError }
}

export default useErrorHandler;
