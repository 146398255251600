import cn from 'classnames';
import React, { useState } from 'react';
import { AddOutline } from 'react-ionicons';
import { useToasts } from 'react-toast-notifications';
import { Button, Card, Loader, TwoFaModal } from '../../components';
import PageWrapper from '../../components/PageWrapper';
import useApiKeys from '../../hooks/useApiKeys';
import ApiKeyCard from './components/ApiKeyCard';

type ActionType = 'SHOW_SECRET' | 'REGENERATE' | 'ADD_NEW' | 'DELETE_KEY';

const ApiKeys: React.FC<unknown> = () => {
  const { addToast } = useToasts();
  const [currentAction, setCurrentAction] = useState<ActionType | undefined>();
  const [credentialId, setCredentialId] = useState<string | undefined>();
  const [show2faModal, setShow2faModal] = useState<boolean>(false);
  const { apiKeys, loading, generateKeys, loadFullKeys, hideKeySecret, destroyKeys, isFetching } = useApiKeys();

  const handleGenerateKey = (totp: string, credentialId?: string) => {
    generateKeys(totp, credentialId)
      .then((res) => {
        res?.error && addToast(res?.error.message, { appearance: 'error' });
      })
      .catch((e) => {
        // TODO: Add sentry
        addToast('Unknown error occurred. Please retry.', { appearance: 'error' });
      })
      .finally(() => setShow2faModal(false));
  };

  const handleDestroyKey = (totp: string, credentialId: string) => {
    destroyKeys(totp, credentialId)
      .then((res) => {
        res?.error && addToast(res?.error.message, { appearance: 'error' });
      })
      .catch((e) => {
        // TODO: Add sentry
        addToast('Unknow error occurred. Please retry.', { appearance: 'error' });
      })
      .finally(() => setShow2faModal(false));
  }

  const handleShowSecret = (totp: string, credentialId: string) => {
    loadFullKeys(totp, credentialId)
      .then((res) => {
        // !res?.error && hideSecretOnTimeout();
        res?.error && addToast(res?.error.message, { appearance: 'error' });
      })
      .catch((e) => {
        // TODO: Add sentry
        addToast('Unknow error occurred. Please retry.', { appearance: 'error' });
      })
      .finally(() => setShow2faModal(false));
  };

  const handleSubmitAction = (totp: string) => {
    if (currentAction === 'ADD_NEW') {
      handleGenerateKey(totp);
    }
    if (currentAction === 'REGENERATE') {
      handleGenerateKey(totp, credentialId);
    }
    if (currentAction === 'SHOW_SECRET') {
      handleShowSecret(totp, credentialId!);
    }
    if((currentAction === 'DELETE_KEY')) {
      handleDestroyKey(totp, credentialId!);
    }
  };

  const initAction = (action: ActionType) => {
    setCurrentAction(action);
    setShow2faModal(true);
  };

  const showSecretAction = (credentialId: string) => {
    setCredentialId(credentialId);
    initAction('SHOW_SECRET');
  };

  const regenerateAction = (credentialId: string) => {
    setCredentialId(credentialId);
    initAction('REGENERATE');
  };

  const removeKeysAction = (credentialId: string) => {
    setCredentialId(credentialId);
    initAction('DELETE_KEY')
  }

  const hideSecretAction = (credentialId: string) => {
    hideKeySecret(credentialId)
  }

  return (
    <PageWrapper title='API Keys'>
      {!isFetching ? (
        <div className='my-10'>
          <div
            className={cn([
              'w-full flex justify-end mb-6',
              {
                hidden: !apiKeys?.length,
              },
            ])}>
            <Button
              text='Add New Key'
              className='py-[10px] font-small'
              icon={<AddOutline color='inherit' />}
              onClick={() => initAction('ADD_NEW')}
            />
          </div>
          <Card>
            {apiKeys?.length ? (
              apiKeys.map((apiKey, key) => (
                <ApiKeyCard
                  apiKey={apiKey}
                  key={key}
                  deleteKey={removeKeysAction}
                  refreshKey={regenerateAction}
                  showSecret={showSecretAction}
                  hideSecret={hideSecretAction}
                />
              ))
            ) : (
              <div className='w-full h-[400px] flex justify-center items-center'>
                <Button
                  text='Add New Key'
                  variant='text'
                  icon={<AddOutline color='inherit' />}
                  onClick={() => initAction('ADD_NEW')}
                />
              </div>
            )}
          </Card>
        </div>
      ) : (
        <div className='w-full h-[200px] flex justify-center items-center'>
          <Loader />
        </div>
      )}
      <TwoFaModal
        show={show2faModal}
        setShow={setShow2faModal}
        loading={loading}
        handleSubmit={handleSubmitAction}
      />
    </PageWrapper>
  );
};

export default ApiKeys;
