import { useNavigate } from "react-router-dom";
import { Card } from "../../../components";
import PageWrapper from "../../../components/PageWrapper";
import useWithdrawal from "../../../hooks/useWithdrawal";
import { ArrowBackOutline } from "react-ionicons";
import { useEffect } from "react";

const ExternalWithdrawal = () => {
  const navigate = useNavigate();
  const {
    isFetching,
    setSelectedToken,
    tokenNetworks,
    tokens,
    selectedToken,
    setSelectedTokenNetwork,
  } = useWithdrawal();

  useEffect(() => {
    // reset on first load
    setSelectedToken("");
    setSelectedTokenNetwork(undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWithdrawalSelection = (type: string, value: any) => {
    switch (type) {
      case "coin":
        // set coin
        setSelectedToken(value);
        break;
      case "network":
        // set network
        setSelectedTokenNetwork(value);
        navigate("complete");

        break;
      default:
        return;
    }
  };

  const handleBack = () => {
    if (!selectedToken) {
      navigate("/withdrawal");
    }
    setSelectedToken("");
  };

  return (
    <PageWrapper title={selectedToken ? "Select Network" : "Select Stablecoin"}>
      {
        <p className="mt-4 flex cursor-pointer" onClick={handleBack}>
          <ArrowBackOutline /> Back{" "}
        </p>
      }
      <div className="flex justify-center w-full">
        {selectedToken ? (
          <Card
            title="Select Network"
            headingClassNames="mb-8 text-center"
            classNames="py-12 px-6 mt-20 min-w-[400px] max-w-[500px] justify-center bg-white shadow-md rounded-md"
            children={
              <>
                {tokenNetworks?.map((network) => {
                  const { name, alias } = network.networkInfo;
                  return network.withdrawalAvailable ? (
                    <p
                      className={`w-full border text-center py-6 my-6 rounded-md hover:bg-gray-100 cursor-pointer`}
                      onClick={() => {
                        if (network.withdrawalAvailable)
                          handleWithdrawalSelection("network", network);
                      }}
                      key={name}
                    >
                      {name}{" "}
                      <span className="text-sm">
                        ({alias}) - Fee ({network.withdrawalFee} {selectedToken}
                        )
                      </span>
                    </p>
                  ) : null;
                })}
              </>
            }
          />
        ) : (
          <Card
            title="Select Stablecoin"
            headingClassNames="mb-8 text-center"
            classNames="py-12 px-6 mt-20 min-w-[400px] max-w-[500px] justify-center bg-white shadow-md rounded-md"
            children={
              <>
                {isFetching ? (
                  <p>Loading</p>
                ) : (
                  tokens?.map((currency) => {
                    return (
                      <p
                        className="w-full border text-center py-6 my-6 rounded-md hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          handleWithdrawalSelection("coin", currency.symbol);
                        }}
                        key={currency.name}
                      >
                        {currency.symbol}
                      </p>
                    );
                  })
                )}
              </>
            }
          />
        )}
      </div>
    </PageWrapper>
  );
};

export default ExternalWithdrawal;
