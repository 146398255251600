import { CexSignerDto } from '@nestcoinco/onboard-api-gateway-api-client';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { createNewSigner, fetchAllSigners, formatError } from '../../api';
import {useAuth, useErrorHandler} from '../../hooks';
import { ContextActionReturnType } from '../../typings';

interface SignerContextProps {
  loading: boolean;
  isFetching: boolean;
  create: (
    networkId: string,
    address: string,
    otp: string
  ) => Promise<ContextActionReturnType | void>;
  signers: CexSignerDto[] | undefined;
  fetchAll: (networkId?: string) => Promise<void>;
}

export const SignerContext = createContext<SignerContextProps | null>(null);

const SignerProvider = (prop: any) => {
  const { authToken, verified } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [signers, setSigners] = useState<CexSignerDto[] | undefined>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { toastError } = useErrorHandler();

  const fetchAll = useCallback(
    async (networkId?: string) => {
      setLoading(true);
      fetchAllSigners(authToken!, networkId)
        .then(setSigners)
        .catch(toastError)
        .finally(() => setLoading(false));
    },
    [authToken, toastError]
  );

  const create = useCallback(
    async (networkId: string, address: string, otp: string) => {
      setLoading(true);
      try {
        if (!authToken) {
          return { error: formatError('Authentication session not found!') };
        }
        await createNewSigner(networkId, address, otp, authToken!);
        fetchAll();
      } catch (e) {
        return { error:  formatError(e) };
      } finally {
        setLoading(false);
      }
    },
    [authToken, fetchAll]
  );

  useEffect(() => {
    if (!authToken || !verified) return;
    setIsFetching(true);
    fetchAllSigners(authToken!)
      .then(setSigners)
      .catch(toastError)
      .finally(() => setIsFetching(false));
  }, [authToken, toastError, verified]);

  const values = useMemo<SignerContextProps>(
    () => ({
      loading,
      create,
      signers,
      fetchAll,
      isFetching,
    }),
    [loading, create, signers, fetchAll, isFetching]
  );

  return <SignerContext.Provider value={values}>{prop.children}</SignerContext.Provider>;
};

export default SignerProvider;
