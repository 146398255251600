import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import PageWrapper from "../../components/PageWrapper";
import {
  TabContent,
  TabContentItem,
  TabNavList,
  TabNavListItm,
} from "../../components/Tabs";
import URL from "./components/URL";
import WebhookSecret from "./components/WebhookSecret";
import Fee from "./components/Fee";
import useProductAccess from "../../hooks/useProductAccess/useProductAccess";
import {
  ONBOARD_DEFI_PRODUCT,
  ONBOARD_NON_DEFI_PRODUCT,
} from "../../constants/products";

const Settings = () => {
  const [params] = useSearchParams();
  const tab: "url" | "secret" | "fee-defi" | "fee-non-defi" | null = params.get(
    "tab"
  ) as any;
  const activeTab = useMemo(() => tab ?? "url", [tab]);
  const { hasDefiOnboardProduct, hasNonDefiOnboardProduct } =
    useProductAccess();

  return (
    <PageWrapper title="Settings">
      <div className="mt-7">
        <TabNavList id="cex-settings">
          <TabNavListItm
            title="URL Configs"
            id="url-configs"
            controls="url-configs-tab"
            showAsDefault={activeTab === "url"}
            tabParam="url"
            className={`
            text-gray-600
              ${
                activeTab === "url"
                  ? "underline text-gray-900 underline-offset-[10px]"
                  : ""
              }
            `}
          />
          <TabNavListItm
            title="Webhook Secret"
            id="api-keys"
            controls="api-keys-tab"
            showAsDefault={activeTab === "secret"}
            tabParam="secret"
            className={`
            text-gray-600
              ${
                activeTab === "secret"
                  ? "underline text-gray-900 underline-offset-[10px]"
                  : ""
              }
            `}
          />
          {hasDefiOnboardProduct ? (
            <TabNavListItm
              title="Fee setup(defi)"
              id="fee-setup-defi"
              controls="fee-setup-defi-tab"
              showAsDefault={activeTab === "fee-defi"}
              tabParam="fee-defi"
              className={`
            text-gray-600
              ${
                activeTab === "fee-defi"
                  ? "underline text-gray-900 underline-offset-[10px]"
                  : ""
              }
            `}
            />
          ) : null}
          {hasNonDefiOnboardProduct ? (
            <TabNavListItm
              title="Fee setup (non-defi)"
              id="fee-setup-non-defi"
              controls="fee-setup-non-defi-tab"
              showAsDefault={activeTab === "fee-non-defi"}
              tabParam="fee-non-defi"
              className={`
            text-gray-600
              ${
                activeTab === "fee-non-defi"
                  ? "underline text-gray-900 underline-offset-[10px]"
                  : ""
              }
            `}
            />
          ) : null}
        </TabNavList>
        <TabContent id="cex-settings-content">
          {activeTab === "url" && (
            <TabContentItem
              id="url-configs-tab"
              controller="url-configs"
              showAsDefault={activeTab === "url"}
            >
              <URL />
            </TabContentItem>
          )}

          {activeTab === "fee-defi" && (
            <TabContentItem id="fee-setup-tab" controller="fee-setup">
              <Fee product={ONBOARD_DEFI_PRODUCT} />
            </TabContentItem>
          )}

          {activeTab === "fee-non-defi" && (
            <TabContentItem id="fee-setup-tab" controller="fee-setup">
              <Fee product={ONBOARD_NON_DEFI_PRODUCT} />
            </TabContentItem>
          )}

          {activeTab === "secret" && (
            <TabContentItem
              id="api-keys-tab"
              controller="api-keys"
              showAsDefault={activeTab === "secret"}
            >
              <WebhookSecret />
            </TabContentItem>
          )}
        </TabContent>
      </div>
    </PageWrapper>
  );
};
export default Settings;
