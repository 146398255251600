import React, { FC, InputHTMLAttributes, ReactNode, useRef } from "react";
import cn from "classnames";
import { AddOutline, RemoveOutline } from "react-ionicons";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  tag?: string | ReactNode;
  disabled?: boolean;
  scale?: "lg" | "sm";
  className?: string;
  onIncrease: VoidFunction;
  onDecrease: VoidFunction;
  error?: string;
}

export const CountInput: FC<InputProps> = ({
  name,
  label,
  value,
  error,
  disabled,
  tag,
  onBlur,
  scale,
  className,
  onChange,
  onIncrease,
  onDecrease,
  ...restProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div className={"relative"}>
      {label ? (
        <label className="text-sm text-gray-900 font-medium mb-1 block">
          {label}
        </label>
      ) : null}
      <div className="relative">
        <input
          ref={inputRef}
          type="number"
          inputMode="numeric"
          name={name}
          id={name}
          {...restProps}
          className={cn([
            "appearance-none block w-full placeholder:text-gray-600 bg-transparent border-2 border-gray-400 rounded-[10px] py-[18px] px-5 mb-1 leading-tight focus:outline-gray-600",
            className,
            {
              "!border-red-500 ": error && error.trim().length > 0,
              "bg-gray-200": disabled,
            },
          ])}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
        <div className="flex absolute right-0 top-0 gap-x-4 h-full items-center pr-5">
          <button
            onClick={onDecrease}
            className="appearance-none h-6 w-6 grid place-items-center"
          >
            <RemoveOutline />
          </button>
          <button
            onClick={onIncrease}
            className="appearance-none h-6 w-6 grid place-items-center"
          >
            <AddOutline />
          </button>
        </div>
      </div>

      {error ? <p className="text-red-500 text-xs italic">{error}</p> : null}
    </div>
  );
};
